import produce from "immer";
import { PureComponent } from "react";

import type { CacheId, ConsumerProps } from "./context";
import {
  CacheBusterConsumer,
  CacheBusterContext,
  CacheBusterProvider,
  getInitialContext,
  withCacheBuster,
} from "./context";
import type { WithCacheBusterIdConsumerProps } from "./with-cache";
import { withCacheBusterId } from "./with-cache";

type Props = {};

export const withLookbooksCacheBuster = withCacheBusterId(
  "lookbooksCacheBuster",
  CacheBusterConsumer,
);
export type LookbooksCacheBusterConsumerProps =
  WithCacheBusterIdConsumerProps<"lookbooksCacheBuster">;

export const withLookbookItemsCacheBuster = withCacheBusterId(
  "lookbookItemsCacheBuster",
  CacheBusterConsumer,
);
export type LookbookItemsCacheBusterConsumerProps =
  WithCacheBusterIdConsumerProps<"lookbookItemsCacheBuster">;

export type CacheBusterConsumerProps = ConsumerProps;
export type CacheBusterProps = Props;

export { CacheBusterContext, CacheBusterConsumer, withCacheBuster };

interface State {
  context: CacheBusterConsumerProps;
}

class CacheBuster extends PureComponent<Props, State> {
  state: State = {
    context: getInitialContext(),
  };

  constructor(props: Props) {
    super(props);

    this.state = produce(this.state, (draft) => {
      draft.context.onBustCache = this.onBustCache;
    });
  }

  onBustCache = (cacheId: CacheId) => {
    this.setState((prev) =>
      produce(prev, (draft) => {
        draft.context.cache[cacheId] = Date.now();
      }),
    );
  };

  render() {
    return (
      <CacheBusterProvider value={this.state.context}>
        {this.props.children}
      </CacheBusterProvider>
    );
  }
}

export default CacheBuster;
