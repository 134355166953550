import { usePrevious } from "@endearhq/app-shared";
import produce from "immer";
import type { ReactNode } from "react";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";

import { RolesContext } from "../Roles";
import type { ConsumerProps } from "./context";
import {
  OrdersCacheConsumer,
  OrdersCacheProvider,
  withOrdersCache,
} from "./context";

interface Props {
  children?: ReactNode;
}

export type OrdersCacheConsumerProps = ConsumerProps;
export type OrdersCacheProps = Props;

export { OrdersCacheConsumer, withOrdersCache };

function OrdersCache(props: Props) {
  const { currentUser } = useContext(RolesContext);

  const [ordersCache, setOrdersCache] = useState<ConsumerProps["ordersCache"]>(
    {},
  );

  const prevCurrentUser = usePrevious(currentUser);
  useEffect(() => {
    if (!currentUser && prevCurrentUser) {
      setOrdersCache({});
    }
  }, [currentUser, prevCurrentUser]);

  const onUpsertOrdersCache = useCallback<
    OrdersCacheConsumerProps["onUpsertOrdersCache"]
  >((orders) => {
    setOrdersCache((prev) =>
      produce(prev, (draft) => {
        orders?.forEach((order) => {
          const existingOrder = draft[order.id];

          if (!existingOrder || existingOrder.updated_at <= order.updated_at) {
            draft[order.id] = order;
          }
        });
      }),
    );
  }, []);

  const context = useMemo(
    () => ({
      ordersCache,
      onUpsertOrdersCache,
    }),
    [ordersCache, onUpsertOrdersCache],
  );

  return (
    <OrdersCacheProvider value={context}>{props.children}</OrdersCacheProvider>
  );
}

export default OrdersCache;
